<template>
  <Item
    v-slot="{ shadowVar }"
    :class="[
      fillClass,
      {
        'grid h-full': isFill,
      },
    ]"
    :item="item"
    :parent="parent"
    :data-fill-type="imgAppearance"
  >
    <div
      class="flex relative flex-col w-full"
      :class="{ 'h-full': isFill }"
      :style="shadowVar"
    >
      <Headline
        v-if="headlineItem"
        :item="headlineItem"
        :parent="item"
        class="shrink-0"
        v-bind="headlineBindings"
      />

      <div class="flex-grow" v-bind="imageItemWrapperBindings">
        <ImageItem
          :class="imageItemClasses"
          :dimensions="dimensions"
          :item="item.items[0]"
          :parent="item"
          :crop="childImgCrop"
          :aspect-ratio="aspectRatio"
          :appearance="isFill ? 'fill' : item.items[0].props.appearance"
          :img-class="imageItemImgClass"
        />
      </div>
    </div>
  </Item>
</template>

<script>
import { get } from 'vuex-pathify'
import itemMixin from '@/mixins/item'
import blockMixin from '@/mixins/block'

export default {
  name: 'ImageBlock',

  components: {
    Item: () => import('@/components/Item'),
    ImageItem: () => import('@/components/Items/ImageItem'),
    Headline: () => import('@/components/Items/Headline'),
  },

  mixins: [blockMixin, itemMixin],

  props: {
    metaData: {
      type: Object,

      default: () => ({}),
    },
  },

  computed: {
    hasRoundedCorners: get('page/hasRoundedCorners'),

    headlineItem() {
      return this.item.items.find(item => item.name === 'Headline')
    },

    align() {
      return this.item.props.align
    },

    parentValign() {
      return this.parent.props.valign
    },

    imgAppearance() {
      return this.item.items[0].props.appearance || ''
    },

    childImgCrop() {
      return this.item.items[0].props.imgCrop
    },

    dimensions() {
      const { dimensions } = this.blocksConfigs.ImageBlock.ImageItem

      if (!this.isHalf) {
        dimensions.width = 1600
      }

      return dimensions
    },

    imgHasShape() {
      return (
        typeof this.imgAppearance === 'string' &&
        this.imgAppearance.startsWith('shape')
      )
    },

    isFill() {
      return this.imgAppearance === 'fill'
    },

    isPng() {
      return this.item.items[0].settings?.filename?.substr(-3) === 'png'
    },

    hasBgColor() {
      return this.item.props.bgColor?.color || this.item.props.bgColor?.gradient
    },

    // Set padding to fill up block container
    // based on img appearance props
    // and parent's width and bg color
    fillClass() {
      const classes = []

      if (this.isFill) {
        if (this.metaData.isExpanded || this.metaData.hasBg) {
          classes.push('fill-padding-none')
        }

        return classes.join(' ')
      }

      if (this.metaData.isExpanded) {
        return null
      }

      return classes.join(' ')
    },

    // Set border radius and shadow classes on child item
    // based on this blocks and parent section's props
    imageItemImgClass() {
      const {
        hasFilledMedia,
        rowHasExpandedCol,
        isExpanded,
        pos,
        rowHasBgCol,
      } = this.metaData

      let data = {
        // 'shadow-xl': !this.isPng,
      }

      if (!this.hasRoundedCorners) {
        return data
      }

      if (
        this.hasRoundedCorners &&
        !(hasFilledMedia && (isExpanded || rowHasExpandedCol))
      ) {
        data = Object.assign(data, {
          'rounded-lg': pos === 'only' || !rowHasBgCol,
          'rounded-l-lg': pos === 'first',
          'rounded-r-lg': pos === 'last',
        })
      }

      return data
    },

    headlineBindings() {
      return {
        class: {
          'mb-8': !this.isFill,
          'absolute transform py-3 z-10': this.isFill,
          'my-4': this.isFill,
          'my-8': this.isFill,
          'left-0 pl-16 pr-8': this.isFill && this.align === 'left',
          'left-1/2 -translate-x-1/2 w-fit px-8':
            this.isFill && this.align === 'center',
          'right-0 pr-16 pl-8': this.isFill && this.align === 'right',
          'top-0': this.isFill && this.parentValign === 'top',
          'bottom-0': this.isFill && this.parentValign === 'bottom',
          'my-0 top-1/2 -translate-y-1/2':
            this.isFill && this.parentValign === 'middle',
        },

        style: this.headlineStyles,
      }
    },

    headlineStyles() {
      if (!this.isFill) {
        return
      }

      return {
        mixBlendMode: 'luminosity',
        backdropFilter: 'blur(5px)',
        backgroundColor:
          'hsla(var(--bgColorH),var(--bgColorS),var(--bgColorL), 0.65)',
      }
    },

    imageItemWrapperBindings() {
      return {
        class: {
          flex: this.imgAppearance === 'org',
          'w-full': !this.isFill,
          'justify-start': this.align === 'left',
          'justify-center': this.align === 'center',
          'justify-end': this.align === 'right',
        },
      }
    },

    imageItemClasses() {
      return {
        'max-w-1/2': this.imgHasShape && !this.isHalf,
        'mr-auto': this.align === 'left',
        'mx-auto': this.align === 'center',
        'ml-auto': this.align === 'right',
        'h-full': this.isFill,
      }
    },

    aspectRatio() {
      if (
        (this.item.props.width === '1:1' || this.metaData.pos === 'only') &&
        this.isFill
      ) {
        return 'aspect-w-16 aspect-h-5'
      }
      return null
    },
  },
}
</script>
